import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/global/layout"
import RegionHero from "../components/regions/region-hero"
import Lead from "../components/global/lead"
import Container from "../components/container/container"
import WineryList from "../components/winery/winery-list"
import ProductSlider from "../components/product/product-slider"

const RegionChild = ({
  data: { datoCmsRegionChild, regionWineries, regionWines },
}) => {
  const { title, description, heroImage, subHeading, parentRegion } =
    datoCmsRegionChild

  return (
    <Layout
      seoTitle={`iHeartWine | Explore Wine Regions | ${title}`}
      seoDescription={description}
    >
      <div>
        <RegionHero
          heading={title}
          image={heroImage || parentRegion.heroImage}
        />
        <Container gutters pt={2} pb={1} width={"small"}>
          <Lead heading={subHeading} text={description} />
        </Container>
        <Container pt={2} pb={1} bg={"light"} width={"none"}>
          <ProductSlider
            heading={`${title} wines`}
            subHeading={"Discover"}
            items={regionWines.nodes}
          />
        </Container>
        <Container gutters width={"large"} pt={2} pb={3}>
          <WineryList items={regionWineries.nodes} title={"Wineries"} />
        </Container>
      </div>
    </Layout>
  )
}

export default RegionChild

export const query = graphql`
  query RegionChildQuery($id: String!, $regionId: Int!) {
    datoCmsRegionChild(originalId: { eq: $id }) {
      title
      slug
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      originalId
      subHeading
      heroImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { w: "1920", h: "750", fit: "crop", q: 60 }
        )
        alt
      }
      description
      parentRegion {
        heroImage {
          gatsbyImageData(
            layout: FULL_WIDTH
            imgixParams: { w: "1920", h: "750", fit: "crop", q: 60 }
          )
          alt
        }
      }
    }
    regionWineries: allPortalWinery(filter: { region_id: { eq: $regionId } }) {
      nodes {
        banner
        address
        name
        region_id
        region_name
        id
        wineryId
        state
        country
        description
      }
    }
    regionWines: allPortalWine(
      filter: { winery: { region_id: { eq: $regionId } } }
    ) {
      nodes {
        id
        name
        price
        rrp
        discounted_price
        image
        wine_type
        year
        grape {
          name
          id
        }
        winery {
          name
          id
          minimum_wines_per_order
          region_id
          region_name
        }
      }
    }
  }
`
