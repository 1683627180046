import React from "react"
import style from "./region-hero.mod.scss"
import BorderWrapper from "../border-wrapper/border-wrapper"
import Container from "../container/container"
import { GatsbyImage } from "gatsby-plugin-image"
import Pin from "../icons/pin"
import Breadcrumbs from "../global/breadcrumbs"

const RegionHero = ({ heading, image, breadcrumbs }) => {
  return (
    <div className={style.hero}>
      <Container>
        <BorderWrapper topColor="light" bottomColor="white">
        {breadcrumbs && <Breadcrumbs items={breadcrumbs} />}
          {image && (
            <div className={style.hero__image}>
              <GatsbyImage image={image.gatsbyImageData} alt={image.alt} />
            </div>
          )}
          <div className={style.hero__content}>
            <Pin />
            <p>{"Region"}</p>
            <h1>{heading}</h1>
          </div>
        </BorderWrapper>
      </Container>
    </div>
  )
}

export default RegionHero
